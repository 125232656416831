import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Categorie } from '../model/categorie';
import { Event } from '../model/event';
import { Format } from '../model/format';
import { Race } from '../model/race';
import { Type } from '../model/type';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(private httpClient: HttpClient) { }

  public getCategories(): Categorie[] {
    return [
      {
        id: 'MP',
        label: 'Mini-poussin(e)',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: false
      },
      {
        id: 'PO',
        label: 'Poussin(e)',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'PU',
        label: 'Pupille',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'BE',
        label: 'Benjamin(e)',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'MI',
        label: 'Minime',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'CA',
        label: 'Cadet(te)',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'JU',
        label: 'Junior',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: true,
        challenge: true
      },
      {
        id: 'SE',
        label: 'Senior',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: false,
        challenge: true
      },
      {
        id: 'VE',
        label: 'Veteran(e)',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: false,
        challenge: true
      }
      ,
      {
        id: 'XX',
        label: 'Inconnu',
        selected: false,
        labelsInResult: [],
        inaffectedCategory: '',
        currentFemaleRank: 0,
        currentMaleRank: 0,
        young: false,
        challenge: false
      }
    ];
  }
  public getFormats(): Format[] {
    return [
      {
        id: 1,
        label: '6-9',
        multiplier: 1
      },
      {
        id: 2,
        label: '10-13',
        multiplier: 1
      },
      {
        id: 3,
        label: '14-19',
        multiplier: 1
      },
      {
        id: 4,
        label: 'XXS',
        multiplier: 0.5
      },
      {
        id: 5,
        label: 'XS',
        multiplier: 0.5
      },
      {
        id: 6,
        label: 'S',
        multiplier: 0.75
      },
      {
        id: 7,
        label: 'M',
        multiplier: 1
      },
      {
        id: 8,
        label: 'L',
        multiplier: 1.5
      },
      {
        id: 9,
        label: 'XL',
        multiplier: 2
      },
      {
        id: 10,
        label: 'XXL',
        multiplier: 2.5
      },
      {
        id: 11,
        label: 'Mini-Poussins',
        multiplier: 1
      },
      {
        id: 12,
        label: 'Poussins',
        multiplier: 1
      },
      {
        id: 13,
        label: 'Pupilles',
        multiplier: 1
      },
      {
        id: 14,
        label: 'Benjamin',
        multiplier: 1
      },
      {
        id: 15,
        label: 'Minimes',
        multiplier: 1
      },
      {
        id: 16,
        label: 'Cadets',
        multiplier: 1
      },
      {
        id: 17,
        label: 'Juniors',
        multiplier: 1
      },
      {
        id: 18,
        label: '6-7',
        multiplier: 1
      },
      {
        id: 19,
        label: '8-9',
        multiplier: 1
      },
      {
        id: 20,
        label: '8-11',
        multiplier: 1
      },
      {
        id: 21,
        label: '8-10',
        multiplier: 1
      },
      {
        id: 22,
        label: '10-11',
        multiplier: 1
      },
      {
        id: 23,
        label: '10-12',
        multiplier: 1
      },
      {
        id: 24,
        label: '13-15',
        multiplier: 1
      }
    ];
  }
  public getTypes(): Type[] {
    return [
      {
        id: 'surname',
        label: 'Nom',
        columnId: 0
      },
      {
        id: 'forename',
        label: 'Prénom',
        columnId: 0
      },
      {
        id: 'license',
        label: 'Licence',
        columnId: 0
      },
      {
        id: 'rank',
        label: 'Classement',
        columnId: 0
      },
      {
        id: 'category',
        label: 'Catégorie',
        columnId: 0
      },
      {
        id: 'gender',
        label: 'Sexe',
        columnId: 0
      },
      {
        id: 'race',
        label: 'Epreuve',
        columnId: 0
      },
      {
        id: 'club',
        label: 'Club',
        columnId: 0
      }
    ];
  }
  public getPointsByRankForAdult(): any[] {
    return [
      { rank: 1, point: 300 },
      { rank: 2, point: 250 },
      { rank: 3, point: 220 },
      { rank: 4, point: 200 },
      { rank: 5, point: 190 },
      { rank: 6, point: 180 },
      { rank: 7, point: 170 },
      { rank: 8, point: 160 },
      { rank: 9, point: 150 },
      { rank: 10, point: 145 },
      { rank: 11, point: 140 },
      { rank: 12, point: 135 },
      { rank: 13, point: 130 },
      { rank: 14, point: 129 },
      { rank: 15, point: 128 },
      { rank: 16, point: 127 },
      { rank: 17, point: 126 },
      { rank: 18, point: 125 },
      { rank: 19, point: 124 },
      { rank: 20, point: 123 },
      { rank: 21, point: 122 },
      { rank: 22, point: 121 },
      { rank: 23, point: 120 },
      { rank: 24, point: 119 },
      { rank: 25, point: 118 },
      { rank: 26, point: 117 },
      { rank: 27, point: 116 },
      { rank: 28, point: 115 },
      { rank: 29, point: 114 },
      { rank: 30, point: 113 },
      { rank: 31, point: 112 },
      { rank: 32, point: 111 },
      { rank: 33, point: 110 },
      { rank: 34, point: 109 },
      { rank: 35, point: 108 },
      { rank: 36, point: 107 },
      { rank: 37, point: 106 },
      { rank: 38, point: 105 },
      { rank: 39, point: 104 },
      { rank: 40, point: 103 },
      { rank: 41, point: 102 },
      { rank: 42, point: 101 },
      { rank: 43, point: 100 },
      { rank: 44, point: 99 },
      { rank: 45, point: 98 },
      { rank: 46, point: 97 },
      { rank: 47, point: 96 },
      { rank: 48, point: 95 },
      { rank: 49, point: 94 },
      { rank: 50, point: 93 },
      { rank: 51, point: 92 },
      { rank: 52, point: 91 },
      { rank: 53, point: 90 },
      { rank: 54, point: 89 },
      { rank: 55, point: 88 },
      { rank: 56, point: 87 },
      { rank: 57, point: 86 },
      { rank: 58, point: 85 },
      { rank: 59, point: 84 },
      { rank: 60, point: 83 },
      { rank: 61, point: 82 },
      { rank: 62, point: 81 },
      { rank: 63, point: 80 },
      { rank: 64, point: 79 },
      { rank: 65, point: 78 },
      { rank: 66, point: 77 },
      { rank: 67, point: 76 },
      { rank: 68, point: 75 },
      { rank: 69, point: 74 },
      { rank: 70, point: 73 },
      { rank: 71, point: 72 },
      { rank: 72, point: 71 },
      { rank: 73, point: 70 },
      { rank: 74, point: 69 },
      { rank: 75, point: 68 },
      { rank: 76, point: 67 },
      { rank: 77, point: 66 },
      { rank: 78, point: 65 },
      { rank: 79, point: 64 },
      { rank: 80, point: 63 },
      { rank: 81, point: 62 },
      { rank: 82, point: 61 },
      { rank: 83, point: 60 },
      { rank: 84, point: 59 },
      { rank: 85, point: 58 },
      { rank: 86, point: 57 },
      { rank: 87, point: 56 },
      { rank: 88, point: 55 },
      { rank: 89, point: 54 },
      { rank: 90, point: 53 },
      { rank: 91, point: 52 },
      { rank: 92, point: 51 },
      { rank: 93, point: 50 },
      { rank: 94, point: 49 },
      { rank: 95, point: 48 },
      { rank: 96, point: 47 },
      { rank: 97, point: 46 },
      { rank: 98, point: 45 },
      { rank: 99, point: 44 },
      { rank: 100, point: 43 },
      { rank: 101, point: 42 },
      { rank: 102, point: 41 },
      { rank: 103, point: 40 },
      { rank: 104, point: 39 },
      { rank: 105, point: 38 },
      { rank: 106, point: 37 },
      { rank: 107, point: 36 },
      { rank: 108, point: 35 },
      { rank: 109, point: 34 },
      { rank: 110, point: 33 },
      { rank: 111, point: 32 },
      { rank: 112, point: 31 },
      { rank: 113, point: 30 },
      { rank: 114, point: 29 },
      { rank: 115, point: 28 },
      { rank: 116, point: 27 },
      { rank: 117, point: 26 },
      { rank: 118, point: 25 },
      { rank: 119, point: 24 },
      { rank: 120, point: 23 },
      { rank: 121, point: 22 },
      { rank: 122, point: 21 },
      { rank: 123, point: 20 },
      { rank: 124, point: 19 },
      { rank: 125, point: 18 },
      { rank: 126, point: 17 },
      { rank: 127, point: 16 },
      { rank: 128, point: 15 },
      { rank: 129, point: 14 },
      { rank: 130, point: 13 },
      { rank: 131, point: 12 },
      { rank: 132, point: 11 },
      { rank: 133, point: 10 },
      { rank: 134, point: 9 },
      { rank: 135, point: 8 },
      { rank: 136, point: 7 },
      { rank: 137, point: 6 },
      { rank: 138, point: 5 },
      { rank: 139, point: 4 },
      { rank: 140, point: 3 },
      { rank: 141, point: 2 },
      { rank: 142, point: 1 }
    ];
  }
  public getPointsByRankForYoung(): any[] {
    return [
      { rank: 1, point: 30 },
      { rank: 2, point: 25 },
      { rank: 3, point: 20 },
      { rank: 4, point: 18 },
      { rank: 5, point: 16 },
      { rank: 6, point: 15 },
      { rank: 7, point: 14 },
      { rank: 8, point: 13 },
      { rank: 9, point: 12 },
      { rank: 10, point: 11 },
      { rank: 11, point: 10 },
      { rank: 12, point: 9 },
      { rank: 13, point: 8 },
      { rank: 14, point: 7 },
      { rank: 15, point: 6 },
      { rank: 16, point: 5 },
      { rank: 17, point: 4 },
      { rank: 18, point: 3 },
      { rank: 19, point: 2 },
      { rank: 20, point: 1 }
    ];
  }

  public getDataFromXlsx(file: File): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const xlsxParser = new Worker('xlsx/xlsx-parser.js');
      xlsxParser.addEventListener('message', (message: MessageEvent) => {
        if (message.data.complete) {
          observer.complete();
        } else if (message.data.error) {
          observer.error(message.data.exception);
          observer.complete();
        } else {
          observer.next(JSON.stringify(message.data));
        }
      });
      const fileReader = new FileReader();
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        xlsxParser.postMessage(e.target.result);
      };
      fileReader.onerror = (ex) => {
        console.log(ex);
        observer.error(ex);
        observer.complete();
      };
      fileReader.readAsBinaryString(file);
    });
  }

  /*
  public getPersons(): void {
    this.httpClient.post('https://espacetri.fftri.com/license/list-licensees-export-to-excel/0/50/0/DESC/0',
      'acceptedExportLog: on', { headers: {'Content-Type': 'application/x-www-form-urlencoded' } })
      .subscribe((res) => console.log(res));
  }
  */

  public postEvent(event: Event): Observable<Event> {
    let token = localStorage.getItem('token');
    return new Observable<Event>((observer: Observer<Event>) => {
      this.httpClient.post(`${environment.wsBasUrl}/race-result/v1/events`, event,
      { headers: { Token: token ? token : ''} })
        .subscribe((res: Event) => { observer.next(res); observer.complete(); });
    });
  }
  public postRace(race: Race, results?: any[]): Observable<Race> {
    let token = localStorage.getItem('token');
    const savingRace: any = race;
    savingRace.results = results === null ? [] : results;
    return new Observable<Race>((observer: Observer<Race>) => {
      this.httpClient.post(`${environment.wsBasUrl}/race-result/v1/races`, savingRace,
      { headers: { Token: token ? token : ''} })
        .subscribe((res: Race) => { observer.next(res); observer.complete(); });
    });
  }
  /*
  public postResults(results: any[], race: Race): Observable<any> {
    let token = localStorage.getItem('token');
    return new Observable<any>((observer: Observer<any>) => {
      this.httpClient.post(`${environment.wsBasUrl}/race-result/v1/races/${race.id}/results`, results,
      { headers: { Token: token ? token : ''} })
        .subscribe((res) => observer.complete());
    });
  }
  */
  public postLicenses(year: number, league: string, licenses: any[]): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.post(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/year/${year}/licenses`, licenses,
    { headers: { Token: token ? token : ''} });
  }
  public getEvents(league: string, annee: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/events/?filter_between_date=${annee}-01-01;${annee}-12-31&sortDesc=date`,
    { headers: { Token: token ? token : ''} });
  }
  public getLastEvents(league: string, count: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/events/?sortDesc=date&limitLine=${count}`,
    { headers: { Token: token ? token : ''} });
  }
  public getRaces(eventId: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/events/${eventId}/races`,
    { headers: { Token: token ? token : ''} });
  }
  public getRaceResults(raceId: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/races/${raceId}/results`,
    { headers: { Token: token ? token : ''} });
  }
  public getRace(id: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/races/${id}`,
    { headers: { Token: token ? token : ''} });
  }
  public getEvent(id: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/events/${id}`,
    { headers: { Token: token ? token : ''} });
  }
  public getEventOfRace(raceId: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/races/${raceId}/events`,
    { headers: { Token: token ? token : ''} });
  }
  public deleteRace(raceId: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.delete(`${environment.wsBasUrl}/race-result/v1/races/${raceId}`,
    { headers: { Token: token ? token : ''} });
  }
  public getLicense(license: string, year: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/year/${year}/licenses/${license}`,
    { headers: { Token: token ? token : ''} });
  }
  public getLicensesList(licenses: string[], year: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/year/${year}/licenses?filter_list_id=${licenses.join("|")}&number=${licenses.length}`,
    { headers: { Token: token ? token : ''} });
  }
  public getLicenseBySurname(surname: string, year: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/year/${year}/licenses/?filter_contain_surname=${surname}&number=5`,
    { headers: { Token: token ? token : ''} });
  }
  public getLicenseByName(surname: string, forename: string, year: number): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient
      .get(`${environment.wsBasUrl}/race-result/v1/year/${year}/licenses/?filter_contain_surname=${surname}&filter_contain_forename=${forename}`,
      { headers: { Token: token ? token : ''} });
  }
  public getMultiSearchLicenseByName(names: any[], year: number): Observable<any[]> {
    let token = localStorage.getItem('token');
    const criteria: string[] = names.map((name: any, index: number) => `s${index + 1}_filter_equal_surname=${name.surname}&s${index + 1}_filter_equal_forename=${name.forename}&s${index + 1}_filter_equal_category=${name.category}`);
    const queryParams: string = criteria.join("&");
    return this.httpClient
      .get<any[]>(`${environment.wsBasUrl}/race-result/v1/year/${year}/licenses/?multisearch=yes&${queryParams}`,
      { headers: { Token: token ? token : ''} });
  }
  public getChallenge(league: string, year: number, category: string, gender: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/challenge/${year}/gender/${gender}/category/${category}`,
    { headers: { Token: token ? token : ''} });
  }
  public getChallengeClub(league: string, year: number, type: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/challenge_club/${year}/${type}`,
    { headers: { Token: token ? token : ''} });
  }
  public getYears(league: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/years`,
    { headers: { Token: token ? token : ''} });
  }
  public getLicenseHistory(license: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/licenses/${license}/history`,
    { headers: { Token: token ? token : ''} });
  }
  public getPercentByGender(league: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/results/genders`,
    { headers: { Token: token ? token : ''} });
  }
  public getCountByFormat(league: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/races/formats`,
    { headers: { Token: token ? token : ''} });
  }
  public getCountResults(league: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/results`,
    { headers: { Token: token ? token : ''} });
  }
  public getCountRaces(league: string): Observable<any> {
    let token = localStorage.getItem('token');
    return this.httpClient.get(`${environment.wsBasUrl}/race-result/v1/leagues/${league}/races`,
    { headers: { Token: token ? token : '' } });
  }
}
