<app-waiting [waiting]="waiting" [message]="waitingMessage"></app-waiting>
<app-popup [message]="message"></app-popup>

<div class="back-dialog couche-100" *ngIf="hideSaveDialogDisplay">
    <div class="save-dialog">
        <div class="window">
            <div class="border">
                <div class="title">
                    Sauvegarde
                </div>
                <div class="content">
                    <div class='line'>
                        <img src="img/minifindstore_spin.gif" class="data" *ngIf="!event.saved">
                        <img src="img/coche-verte.png" class="data" *ngIf="event.saved">
                        Evènement {{event.label}}
                    </div>
                    <div class='line' *ngFor="let race of savingSheet.races">
                        <img src="img/minifindstore_spin.gif" class="data" *ngIf="!race.saved">
                        <img src="img/coche-verte.png" class="data" *ngIf="race.saved">
                        Epreuve {{race.label}}
                    </div>
                    <div class='line' *ngFor="let race of savingSheet.races">
                        <img src="img/minifindstore_spin.gif" class="data" *ngIf="!race.resultsSaved">
                        <img src="img/coche-verte.png" class="data" *ngIf="race.resultsSaved">
                        Résultats de l'épreuve {{race.label}}
                    </div>
                </div>
                <div class="button">
                    <input type="button" value="OK" (click)="hideSaveDialog()">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="form" style="padding: 10px; ">
    <h1>Import de résultats</h1>
    <div class="block spaced">
        <div class="label">Nom de l'évènement : </div><div class="enter"><input type="text" [(ngModel)]="event.label" [disabled]="event.id > 0"></div>
    </div>
    <div class="block spaced">
        <div class="label">Date : </div><div class="enter"><input type="text" [(ngModel)]="event.date" placeholder="jj/mm/aaaa" (keyup)="changeDate()" [disabled]="event.id > 0"></div>
    </div>
    <div class="block spaced" *ngIf="displayFile">
        <div class="label">Fichier : </div><div class="enter"><input type="file" id="fileToImport" (change)="loadFile($event.target.files)" accept=".xlsx"></div>
    </div>
    <div>
        <div style="margin-left: 500px;"><input type="button" value="Réinitialiser" (click)="init()"></div>
    </div>

    <div *ngIf="fileLoaded && displayFile">
        <div *ngFor="let sheet of sheets">
            <div class="new-section title-sheet">{{sheet.id}}</div>
            <div *ngIf="sheet.visibility" class="new-section">
                <h1>Association des colonnes</h1>
                <div *ngFor="let type of sheet.types">
                    <div class="block" *ngIf="((type.id != 'category' || !sheet.uniqueCategory) && type.id != 'race') || (type.id == 'race' && sheet.multipleRace)">
                        <div class="label">{{type.label}}</div>
                        <div class="enter">
                            <select [(ngModel)]="type.columnId" (change)="updateResultsDisplay(sheet, type)">
                                <option *ngFor="let column of sheet.columns" [value]="column.id">{{column.label}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <h1>Description des épreuves</h1>
                <div class="line">
                    <input type="checkbox" [(ngModel)]="sheet.multipleRace" (change)="updateMultiRace(sheet)"> Epreuves multiples par feuille
                </div>
                <span *ngIf="sheet.multipleRace">Epreuves :</span> 
                <div class="new-section">
                    <div *ngFor="let race of sheet.races" style="float: left;">
                        <div class="block">
                            <div class="label" *ngIf="sheet.multipleRace">Format de la course "{{race.label}}" : </div>
                            <div class="label" *ngIf="!sheet.multipleRace">Intitulé : <input type="text" [(ngModel)]="race.label"></div>
                        </div>
                        <div class="block">
                            <div class="label" *ngIf="!sheet.multipleRace">Format : </div>
                            <div class="enter">
                                <select [(ngModel)]="race.format">
                                    <option *ngFor="let format of formats" [value]="format.id">{{format.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="new-section line">
                            <input type="checkbox" [(ngModel)]="race.uniqueGender" (change)="changeGender(sheet)"> Sexe unique
                        </div>
                        <div class="block" *ngIf="race.uniqueGender">
                            <div class="label">Sexe de l'épreuve :</div>
                            <div class="enter">
                                <select [(ngModel)]="race.gender" (change)="changeGender(sheet)">
                                    <option *ngFor="let gender of [ '', 'F', 'M' ]" [value]="gender">{{gender}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="!race.uniqueGender">
                            <div class="block">
                                <div class="label">Masculin :</div>
                                <div class="enter">
                                    <select [(ngModel)]="race.maleLabel">
                                        <option *ngFor="let gender of race.genders" [value]="gender.code">{{gender.label}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="block">
                                <div class="label">Féminin :</div>
                                <div class="enter">
                                    <select [(ngModel)]="race.femaleLabel">
                                        <option *ngFor="let gender of race.genders" [value]="gender.code">{{gender.label}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="new-section line">
                            <input type="checkbox" [(ngModel)]="race.uniqueCategory"> Catégorie unique
                        </div>
                        <div *ngIf="!race.uniqueCategory">
                            <div class="line">Epreuve marquante pour les catégories :</div>
                            <div class="list block" *ngFor="let categorie of race.categoriesForPoint">
                                <div class="label">
                                    <input type="checkbox" [(ngModel)]="categorie.selected">&nbsp;<span>{{categorie.label}}</span>
                                </div>
                                <div class="spot" *ngFor="let categorieLabel of categorie.labelsInResult">
                                    <div class="table">
                                        {{categorieLabel}} 
                                        <span class="pointer inner" (click)="deleteAssociationCategory(categorieLabel, categorie, race, sheet)"><img src="img/cross.png" style="width: 17px;"></span>
                                    </div>
                                </div>
                                <div class="label" *ngIf="race.inaffectedCategories.length > 0">
                                    <select [(ngModel)]="categorie.inaffectedCategory">
                                        <option value="">-- selectionnez une catégorie --</option>
                                        <option *ngFor="let inaffectedCategory of race.inaffectedCategories" [value]="inaffectedCategory.code">{{inaffectedCategory.label}}</option>
                                    </select>
                                    <input type="button" value="Ajouter" (click)="addCategory(race, categorie, sheet)">
                                </div>
                            </div>
                        </div>
                        <div *ngIf="race.uniqueCategory" class="block">
                            <div class="label">Catégorie de l'épreuve :</div>
                            <div class="enter">
                                <select [(ngModel)]="race.category">
                                    <option *ngFor="let categorie of race.categoriesForPoint" [value]="categorie.id">{{categorie.label}}</option>
                                </select>
                            </div>
                        </div>
    
                    </div>
                    <div style="clear: both;"></div>
                </div>
                <div class="line new-section">
                    <input type="button" value="calculer" (click)="calculate(sheet)">
                    <input type="button" value="Enregistrer" (click)="save(sheet)" *ngIf="!sheet.saved && sheet.calculated">
                </div>
                <div>
                    <div>Nombre de lignes : {{sheet.results.length}}</div>
                    <div>Nombre de licences trouvées : {{sheet.licensesFound}}</div>
                </div>
                <!--</div>-->
                <div class="new-section">
                    <table>
                        <tr>
                            <th rowspan="2"  class="col-header"></th>
                            <th rowspan="2"  class="col-header bgc">Clt</th>
                            <th rowspan="2"  class="col-header bgc">Cl./S./Cat.</th>
                            <th rowspan="2"  class="col-header bgc">Nom</th>
                            <th rowspan="2"  class="col-header bgc">Prénom</th>
                            <th rowspan="2"  class="col-header bgc">Licence</th>
                            <th rowspan="2"  class="col-header bgc">Club</th>
                            <th rowspan="2"  class="col-header bgc">Sexe</th>
                            <th rowspan="2"  class="col-header bgc">Catégorie</th>
                            <th rowspan="2"  class="col-header bgc" *ngIf="sheet.multipleRace">Epreuve</th>
                            <th rowspan="2"  class="col-header bgc">Points au<br/>challenge</th>
                            <th colspan="3" class="col-header bgc" style="text-align: center;">Correspondance</th>
                        </tr>
                        <tr>
                            <th class="col-header bgc">Licence</th>
                            <th class="col-header bgc">Nom Prénom</th>
                            <th class="col-header bgc">Club</th>
                        </tr>
                        <tr class="tab-line" *ngFor="let result of sheet.results ; index as i">
                            <td class="cell">
                                <img src="img/coche-verte.png" class="icone" *ngIf="result.check && !result.error && !result.attention">
                                <img src="img/croix-rouge.png" class="icone" *ngIf="result.error">
                                <img src="img/attention.png" class="icone" *ngIf="result.attention && !result.error">
                            </td>
                            <td class="cell">{{result.rank}}</td>
                            <td class="cell">{{result.rankCat}}</td>
                            <td class="cell" [ngClass]="{ 'difference': result.surname != result.surnameRef && result.surnameRef != null }">{{result.surname}}</td>
                            <td class="cell">{{result.forename}}</td>
                            <td class="cell">{{result.license}}</td>
                            <td class="cell">{{result.club}}</td>
                            <td class="cell">{{result.gender}}</td>
                            <td class="cell">{{result.category}}</td>
                            <td class="cell" *ngIf="sheet.multipleRace">{{result.race}}</td>
                            <td class="cell">{{result.point}}</td>
                            <td class="cell">{{result.licenseRef}} ({{result.categoryRef}}{{result.genderRef}})</td>
                            <td class="cell">
                                <div *ngIf="!result.seekName">
                                    <div style="float: left;">{{result.nameRef}}</div>
                                    &nbsp;
                                    <div style="float: right;" (click)="searchLicense(sheet, result, i)"><img src="img/crayon.png" class="icone"></div>
                                    <div style="clear: both;"></div>
                                </div>
                                <div><input type="text" [(ngModel)]="result.nameSeeking" id="nameSeeking{{i}}" (keyup)="readLicenses(result)" *ngIf="result.seekName" (blur)="blur(result)" (keyup.escape)="blur(result)"></div>
                                <div *ngIf="result.licensesFound" class="list-results">
                                    <div style="cursor: pointer;" (click)="selectLicense(result, suggestingLicense, sheet)" *ngFor="let suggestingLicense of result.suggestingLicenses">{{suggestingLicense.surname}} {{suggestingLicense.forename}} ({{suggestingLicense.club}})</div>
                                </div>
                            </td>
                            <td class="cell">{{result.clubRef}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>